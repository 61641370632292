.header-top {
    border-bottom: 1px solid #ececec; }
  
  /*-- Header Top Left --*/
  .header-top-left {
    margin: 8px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    @media only screen and (max-width: 479px) {
      .header-top-left {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; } }
    .header-top-left p {
      font-size: 13px;
      line-height: 28px;
      color: #666666;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      margin-right: 25px;
      display: inline-block;
      margin-bottom: 0; }
      .header-top-left p:last-child {
        margin-right: 0; }
      @media only screen and (max-width: 479px) {
        .header-top-left p {
          display: block;
          line-height: 20px;
          margin: 0; }
          .header-top-left p:first-child {
            margin-bottom: 5px; } }
      .header-top-left p span {
        color: #6ab43e;
        font-weight: 400; }
  
  /*-- Header Top Right --*/
  .header-top-right {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /*-- Header Social --*/ }
    .header-top-right .get-quote {
      height: 45px;
      padding: 10px 40px;
      line-height: 25px;
      display: block;
      background-color: #6ab43e;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      border: none;
      margin-bottom: -1px; }
      .header-top-right .get-quote:hover {
        background-color: #77c14b; }
    .header-top-right .header-social a {
      display: block;
      float: left;
      width: 45px;
      height: 44px;
      padding: 10px;
      text-align: center;
      color: #777777;
      border-left: 1px solid #ececec; }
      .header-top-right .header-social a:hover {
        color: #6ab43e; }
      .header-top-right .header-social a i {
        line-height: 24px;
        display: block;
        font-size: 14px; }
  
  /*-- Header Bottom --*/
  /*-- Header Logo --*/
  .header-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 0; }
    .header-logo .logo img {
      width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-logo .logo {
        max-width: 200px; } }
    @media only screen and (max-width: 767px) {
      .header-logo .logo {
        max-width: 200px; } }
    @media only screen and (max-width: 479px) {
      .header-logo .logo {
        max-width: 140px; } }
  
  /*-- Header Search Wrapper --*/
  .header-search-wrapper {
    padding-top: 39px;
    padding-bottom: 39px;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .header-search-wrapper {
        padding-top: 23px;
        padding-bottom: 23px;
        margin-right: 47px; } }
    @media only screen and (max-width: 479px) {
      .header-search-wrapper {
        padding-top: 18px;
        padding-bottom: 18px; } }
    .header-search-wrapper .search-toggle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #6ab43e;
      color: #ffffff;
      display: block;
      border: none; }
      .header-search-wrapper .search-toggle:hover {
        background-color: #77c14b; }
      .header-search-wrapper .search-toggle i {
        font-size: 18px;
        line-height: 32px; }
  
  /*-- Header Search --*/
  .header-search {
    background-color: #ffffff;
    position: absolute;
    right: 0;
    margin-top: 30px;
    padding: 15px;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -99;
    -webkit-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3); }
    @media only screen and (max-width: 479px) {
      .header-search {
        right: -50px; } }
    .header-search.open {
      margin-top: 0;
      z-index: 9999;
      visibility: visible;
      opacity: 1; }
  
  /*-- Header Search Form --*/
  #header-search-form {
    float: left;
    position: relative; }
    #header-search-form input[type="text"] {
      width: 300px;
      display: block;
      float: left;
      height: 44px;
      background-color: #f5f5f5;
      border: none;
      font-weight: 300;
      padding: 10px 44px 10px 15px; }
      @media only screen and (max-width: 479px) {
        #header-search-form input[type="text"] {
          width: 250px; } }
    #header-search-form button {
      display: block;
      float: left;
      background-color: #f5f5f5;
      border: none;
      height: 44px;
      width: 44px;
      text-align: center;
      padding: 10px;
      position: absolute;
      right: 0;
      top: 0; }
      #header-search-form button:hover {
        color: #6ab43e; }
      #header-search-form button i {
        font-size: 20px;
        line-height: 24px;
        display: block; }
  

  